import { LngLat, type MapLayerMouseEvent } from 'mapbox-gl';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { assetLayers, LayerId } from '@components/MapSource/constants';
import { useAssetInfos } from '@hooks/useAssetInfos';
import type { Asset, AssetType } from '@models/asset';

const hasFeatures = (e: MapLayerMouseEvent) => e.features?.length;
const hasLayerId = (e: MapLayerMouseEvent, layerIds: string[]) => {
  if (!e.features?.[0].layer?.id) return false;

  return layerIds.includes(e.features?.[0].layer.id);
};

export const useMapState = () => {
  const [selectedFeatures, setSelectedFeatures] = useState<Asset[]>([]);
  const [popupCoordinates, setPopupCoordinates] = useState<LngLat | null>(null);
  const [projectName, setProjectName] = useState('');
  const { assetInfos } = useAssetInfos();

  const location = useLocation();
  const navigate = useNavigate();

  const setAssetQueryParam = (type: AssetType, id: string) => {
    const hash = window.location.hash;
    const newUrlSearchParams = new URLSearchParams(location.search);

    newUrlSearchParams.set('asset', `${type}.${id}`);
    navigate({ ...location, hash, search: newUrlSearchParams.toString() });
  };

  const removeAssetQueryParam = () => {
    const hash = window.location.hash;
    const newUrlSearchParams = new URLSearchParams(location.search);

    newUrlSearchParams.delete('asset');
    navigate({ ...location, hash, search: newUrlSearchParams.toString() });
  };

  const onSelectFeature = (assets: Asset[]) => {
    if (assets.length > 0) {
      const [asset] = assets;

      setAssetQueryParam(asset.asset_type, asset.id);
    } else {
      removeAssetQueryParam();
    }

    setSelectedFeatures(assets);
  };

  const selectPointFeature = (e: MapLayerMouseEvent) => {
    setPopupCoordinates(null);

    if (!hasFeatures(e) || !hasLayerId(e, assetLayers)) {
      onSelectFeature([]);

      return;
    }

    const uniqueFeatures = e.features?.filter(
      (feature, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.properties?.id === feature.properties?.id &&
            t.layer?.['source'] === feature.layer?.['source'],
        ),
    );

    if (e.originalEvent.shiftKey) {
      const selectionSet = selectedFeatures.filter(
        (f) => f.id !== uniqueFeatures?.[0].properties?.id,
      );

      onSelectFeature(
        selectionSet.length < selectedFeatures.length
          ? selectionSet
          : [
              ...selectionSet,
              {
                ...uniqueFeatures?.[0].properties,
                asset_type: uniqueFeatures?.[0].layer?.['source'],
              } as Asset,
            ],
      );
    } else {
      onSelectFeature([
        {
          ...uniqueFeatures?.[0].properties,
          asset_type: uniqueFeatures?.[0].layer?.['source'],
        } as Asset,
      ]);
    }
  };

  const showProjectNamePopup = (e: MapLayerMouseEvent) => {
    if (!hasFeatures(e) || !hasLayerId(e, [LayerId.PROJECT_LINE, LayerId.PROJECT_FILL])) {
      setPopupCoordinates(null);

      return;
    }
    setPopupCoordinates(e.lngLat);
    setProjectName(e.features?.[0].properties?.name);
  };

  const getSelectedFeatures = () => {
    if (selectedFeatures.length <= 0) return assetInfos;

    return selectedFeatures;
  };

  return {
    selectedFeatures: getSelectedFeatures(),
    setSelectedFeatures: onSelectFeature,
    popupCoordinates,
    setPopupCoordinates,
    projectName,
    setProjectName,
    selectPointFeature,
    showProjectNamePopup,
  };
};

import { type MapEventType, type Event } from 'mapbox-gl';
import { useEffect } from 'react';
import { useMap } from 'react-map-gl';

export function useMapEvent<T extends MapEventType>(
  type: T,
  onInteraction: (event: Event) => void,
) {
  const { current: map } = useMap();

  useEffect(() => {
    if (!map) return;

    map.on(type, onInteraction);

    return () => {
      map.off(type, onInteraction);
    };
  }, [type, map, onInteraction]);
}

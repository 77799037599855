import {
  CheckmarkCircleFilled,
  CircleExclamationMarkFilled,
  CircleInformationFilled,
  WarningFilled,
} from '@deepup/icons';
import { Alert } from '@mui/material';
import { closeSnackbar, type CustomContentProps } from 'notistack';
import { forwardRef, type FC, type ReactNode } from 'react';

type SnackbarIconProps = {
  variant: CustomContentProps['variant'];
};

const SnackbarIcon: FC<SnackbarIconProps> = ({ variant }) => {
  if (variant === 'success') {
    return <CheckmarkCircleFilled />;
  }
  if (variant === 'error') {
    return <CircleExclamationMarkFilled />;
  }
  if (variant === 'warning') {
    return <WarningFilled />;
  }

  return <CircleInformationFilled />;
};

export const SnackbarNotification = forwardRef(
  (props: CustomContentProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { variant, message, id, action } = props;
    const validatedVariant = variant === 'default' ? 'info' : variant;

    return (
      <Alert
        action={action as ReactNode}
        icon={<SnackbarIcon variant={variant} />}
        onClose={() => closeSnackbar(id)}
        ref={ref}
        severity={validatedVariant}
      >
        {String(message)}
      </Alert>
    );
  },
);

SnackbarNotification.displayName = 'SnackbarNotification';
